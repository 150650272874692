import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#3F0180',
        },
        secondary: {
            main: '#fff',
            contrastText: '#3F0180',
        },
    },
    typography: {
        h1: {
            '@media (max-width:600px)': {
                fontSize: '4rem',
            },
            color: '#3F0180',
        },
        h4: {
            color: '#3E0080',
        },
        body2: {
            fontSize: "18px"
        },
        body1: {
            fontSize: "18px"
        }
    }
});

import Container from "@mui/material/Container";
import {Box, Button, Typography} from "@mui/material";
import Experience from "./Experience";
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Link } from "react-router-dom";
import styles from "./home.module.css";

const button_links = [
    { title: 'Home', link: '/' },
    { title: 'Newfoundland Outdoor', link: '/blog' },
    { title: 'Sentiment Analysis', link: '/sentiment-analysis' }
];

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" to="/" style={{ textDecoration: 'none' }}>
                Murisa
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function Footer()
{
    return(
        <Container>
            <Experience/>
            <Box textAlign='center'>
                {button_links.map((btnLink) => (
                    <Button
                        href={btnLink.link}
                        sx={{
                            m: { xs: 1, md: 2 },
                            textAlign: 'center'
                        }}
                    >
                        <Link to={ btnLink.link } className={styles.navLinks}>{btnLink.title}</Link>
                    </Button>
                ))}
            </Box>
            <Box textAlign='center'>
                <InstagramIcon
                    sx={{ m: { xs: 1, md: 2 } }}
                >
                </InstagramIcon>
                <TwitterIcon
                    sx={{ m: { xs: 1, md: 2 } }}
                >
                </TwitterIcon>
            </Box>
            <Copyright/>
        </Container>
    )
}

export default Footer

import LandingSection from "./home/Landing";
import OutdoorNl from "./home/OutdoorNl";
import TrendingAPI from "./home/TrendingAPI";

function Home() {
    return (
        <>
            <LandingSection />
            <TrendingAPI />
            <OutdoorNl />
        </>
    )
}

export default Home;

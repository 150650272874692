function NoPage() {
    return (
        <>
            <h1>404</h1>
            <h2>Nothing here</h2>
        </>
    )
}

export default NoPage;

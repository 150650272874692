import {Box, Grid} from "@mui/material";
import * as React from "react";

const experience_icons = [
    { alt: "Java", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg"},
    { alt: "Python", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg"},
    { alt: "Google Cloud", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/googlecloud/googlecloud-original.svg"},
    { alt: "Amazon Web Services", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/amazonwebservices/amazonwebservices-original.svg"},
    { alt: "Terraform", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/terraform/terraform-original.svg"},
    { alt: "Docker", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original.svg"},
    { alt: "Git", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg"}
]

function Experience()
{
    return(
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item md={8} xs={12} sx={{ textAlign: 'center' }}>
                {experience_icons.map((expIcon) => (
                    <Box
                        key={expIcon.alt}
                        justifyContent="center"
                        alignItems="center"
                        component="img"
                        sx={{
                            m: { xs: 1, md: 2 },
                            height: 'auto',
                            width: 'inherit',
                            textAlign: 'center',
                            maxHeight: { xs: 30, md: 50 },
                            maxWidth: { xs: 30, md:  50 },
                        }}
                        alt={expIcon.alt}
                        src={expIcon.link}
                    >
                    </Box>
                ))}
            </Grid>
        </Grid>
    );
}

export default Experience;

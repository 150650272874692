import Container from "@mui/material/Container";
import {Button, Box, Card, CardActions, CardContent, CardMedia, Grid, Typography} from "@mui/material";
import StorageIcon from '@mui/icons-material/Storage';
import InsightsIcon from '@mui/icons-material/Insights';
import ApiIcon from '@mui/icons-material/Api';
import { Link } from "react-router-dom";

function TrendingAPI()
{
    return(
        <Container maxWidth="xl" sx={{ paddingLeft: { xs: 0 }, paddingRight: { xs: 0 }} }>
            <Grid container>
                <Grid item sx={{ padding: "8px", marginTop: "30px" }}>
                    <Typography variant="h4" component="h4">
                        Project: Entity Sentiment Analysis
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        Working on developing a catalogue of sentiment scores for entities mentioned in news articles.
                    </Typography>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={6}>
                    <Box
                        component="img"
                        sx={{
                        height: 'auto',
                            width: 'inherit',
                            maxHeight: { xs: '100%', md: '100%' },
                            maxWidth: { xs: '100%', md:  '100%' },
                        }}
                        alt="Isaac Murisa"
                        src="emotion_edited.jpg"
                    />
                </Grid>

                <Grid  item xs={12} md={6} sx={{margin: 'auto'}}>
                    <Card
                        elevation={0}
                        sx={{
                            margin: 'auto',
                            justifySelf: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CardContent>
                            <Typography variant="body2" component="body2" fontSize={18} sx={{ lineHeight: "1.8em" }} >
                                <Typography variant="overline" fontWeight={900}>Entities</Typography> in this context refer to anything with proper nouns such as personal names,
                                place names, names of companies and organizations, and the titles of films,etc.<br></br><br></br>
                                Using natural language processing, we can identify and study the prevailing emotional opinions of entities in news headlines.
                                Such data can assist in analyzing the sentiment of an entity over some time
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" startIcon={<ApiIcon />} sx={{ marginBottom: "10px" }}>
                                <Link to="/sentiment-analysis" style={{ color: '#fff', textDecoration: 'none' }}>Dashboard</Link>
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>

            </Grid>


            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                    backgroundColor: "#3F0180",
                    color: "white",
                    marginTop: "30px",
                    padding: "30px 0"
                }}
            >
                <Grid item xs={12} md={3}>
                    <Card
                        elevation={0}
                        sx={{
                            margin: 'auto',
                            justifySelf: 'center',
                            alignItems: 'center',
                            backgroundColor: "inherit",
                            color: "inherit"
                        }}
                    >
                        <CardMedia sx={{ textAlign: 'center', color: 'green' }}>
                            <StorageIcon sx={{ color: "white", fontSize: "xxx-large" }}/>
                        </CardMedia>
                        <CardContent>
                            <Typography variant="body2" textAlign="center">
                                1. Perform entity sentiment analysis on news articles.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Card
                        elevation={0}
                        sx={{
                            margin: 'auto',
                            justifySelf: 'center',
                            alignItems: 'center',
                            backgroundColor: "inherit",
                            color: "inherit"
                        }}
                    >
                        <CardMedia sx={{ textAlign: 'center', color: 'green' }}>
                            <InsightsIcon sx={{ color: "white", fontSize: "xxx-large" }}/>
                        </CardMedia>
                        <CardContent>
                            <Typography variant="body2" textAlign="center">
                                2. Display entity sentiment data on Dashboard.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Card
                        elevation={0}
                        sx={{
                            margin: 'auto',
                            justifySelf: 'center',
                            alignItems: 'center',
                            backgroundColor: "inherit",
                            color: "inherit"
                        }}
                    >
                        <CardMedia sx={{ textAlign: 'center', color: 'green' }}>
                            <ApiIcon sx={{ color: "white", fontSize: "xxx-large" }}/>
                        </CardMedia>
                        <CardContent>
                            <Typography variant="body2" textAlign="center">
                                3. Coming Soon; an API to provide access to entity sentiment data.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

export default TrendingAPI;

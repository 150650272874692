import {Box, Card, CardActions, CardContent, Grid, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import EmailIcon from '@mui/icons-material/Email';

function LandingSection()
{
    return(
        <Container maxWidth="xl" sx={{ paddingLeft: { xs: 0 }, paddingRight: { xs: 0 }} }>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12} md={5}>
                    <Card
                        elevation={0}
                        sx={{
                            width: { md: '75%'},
                            justifySelf: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <CardContent>
                            <Typography variant="h1" color={"primary"} component="h1" sx={{ color: "primary", marginBottom: "30px" }}>
                                greetings!
                            </Typography>
                            <Typography variant="body2" fontSize={18} sx={{ lineHeight: "1.8em" }}>
                                I'm someone who loves to try out new software and engage in outdoor activities.
                                It's always exciting to discover new things, whether it's technology or the great outdoors.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" startIcon={<EmailIcon />}>
                                Let's Chat
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6} sx={{ overflow: "hidden" }}>
                    <Box
                        component="img"
                        sx={{
                        height: 'auto',
                            width: 'inherit',
                            maxHeight: { xs: '100%', md: '100%' },
                            maxWidth: { xs: '100%', md:  '100%' },
                        }}
                        alt="Isaac Murisa"
                        src="phone2.png"
                    />
                </Grid>

            </Grid>
        </Container>
    )
}

export default LandingSection

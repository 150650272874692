import { Container, Grid , Typography, Box, Button } from "@mui/material";
import { ImageList, ImageListItem } from "@mui/material";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { Card, CardContent, CardActions } from "@mui/material";
import { Link } from "react-router-dom";


function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
}


const itemData = [
    {
        img: "outdoor/road.jpg",
        title: "Gros Morne Drive",
        rows: "2"
    },
    {
        img: "outdoor/lookout.jpg",
        title: "Lookout"
    },
    {
        img: "outdoor/stairs.jpg",
        title: "Gros Morne Hike",
        rows: "2"
    },
    {
        img: "outdoor/tablelands.jpg",
        title: "Tablelands"
    },
    {
        img: "outdoor/signalhill.jpeg",
        title: "Signal Hill"
    },
    {
        img: "outdoor/mountain.jpg",
        title: "Oulook"
    }
]

function OutdoorNl() {
    return (
        <Container maxWidth="xl" sx={{ paddingLeft: { xs: 0 }, paddingRight: { xs: 0 }, margin: "32px auto" }}>
            <Grid container>

                <Grid item sx={{ padding: "8px", marginTop: "30px" }}>
                    <Typography variant="h4" component="div" sx={{ color: "primary" }}>
                        Newfoundland Outdoor
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        Looking for a year-round curated plan of outdoor activities to do in Newfoundland? Look no further!
                    </Typography>
                </Grid>

                <Grid>
                    <Card
                        elevation={0}
                        sx={{
                            margin: 'auto',
                            justifySelf: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CardContent>
                            <Typography variant="body2" component="body1" fontSize={18} sx={{ lineHeight: "1.8em" }} >
                                Discover a world of adventure in Newfoundland and Labrador!
                                Our carefully curated catalogue features a wide range of exciting outdoor activities that you can enjoy all year round.
                                Whether you're looking for a thrilling winter adventure or a summer hike with breathtaking views, we've got you covered.
                                Don't miss out on the opportunity to explore the stunning natural beauty of Newfoundland and Labrador.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" startIcon={<HourglassBottomIcon />} sx={{ marginBottom: "10px" }}>
                                <Link to="/" style={{ color: '#fff', textDecoration: 'none' }}>Coming Soon</Link>
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                
                <Grid item>
                    <Box sx={{ width: "100%", height: "100%" }}>
                        <ImageList variant="masonry" cols={3} gap={8}>
                            {itemData.map((item) => (
                            <ImageListItem key={item.img}>
                                <img
                                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                src={`${item.img}?w=248&fit=crop&auto=format`}
                                alt={item.title}
                                loading="lazy"
                                />
                            </ImageListItem>
                            ))}
                        </ImageList>
                    </Box>

                </Grid>

            </Grid>
        </Container>
    )
}

export default OutdoorNl;

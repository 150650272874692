import { Outlet } from "react-router-dom";
import AppBarHeader from "./AppBarHeader";
import Footer from "./Footer";

function Layout() {
    return (
        <div className="mainContainer">
            <AppBarHeader />
            <Outlet />
            <Footer />
        </div>
    )
}

export default Layout;
